import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02ba13b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-main" }
const _hoisted_2 = { class: "part4" }
const _hoisted_3 = { class: "part4-left" }
const _hoisted_4 = ["onMouseenter"]
const _hoisted_5 = { class: "part4-right" }
const _hoisted_6 = { class: "img-box" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IData, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass([{ active: index === _ctx.curType }]),
            key: index,
            onMouseenter: ($event: any) => (_ctx.scrollStop(Number(index))),
            onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollContinue && _ctx.scrollContinue(...args)))
          }, _toDisplayString(item.name), 43 /* TEXT, CLASS, PROPS, NEED_HYDRATION */, _hoisted_4))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_TransitionGroup, { name: "opa-fade" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IData, (item, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "right-content",
                key: item.img
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: require('/public/img/' + item.img),
                    alt: ""
                  }, null, 8 /* PROPS */, _hoisted_7)
                ]),
                _createElementVNode("p", null, _toDisplayString(item.content), 1 /* TEXT */)
              ])), [
                [_vShow, _ctx.curType == index]
              ])
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}