import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02abfc2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-main" }
const _hoisted_2 = { class: "part2-ul" }
const _hoisted_3 = { class: "feature-title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IData, (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              innerHTML: item.name
            }, null, 8 /* PROPS */, _hoisted_4)
          ]),
          _createElementVNode("p", null, _toDisplayString(item.content), 1 /* TEXT */)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}