
import { defineComponent, ref, onBeforeUnmount, onMounted } from 'vue'
import { IComObj } from '@/global/types'
export default defineComponent({
  name: '',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const IData = ref<IComObj>([])
    IData.value = props.data
    const curType = ref(0)
    let timer: any = null
    onMounted(() => {
      typeClick()
    })
    const typeClick = () => {
      timer = setInterval(() => {
        curType.value++
        if (curType.value > props.data.length - 1) {
          curType.value = 0
        }
      }, 5000)
    }
    const scrollStop = (index: number) => {
      curType.value = index
      clearInterval(timer)
    }
    const scrollContinue = () => {
      typeClick()
    }
    onBeforeUnmount(() => {
      clearInterval(timer)
    })
    return { IData, curType, typeClick, scrollStop, scrollContinue }
  }
})
