// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/part2-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./img/part4-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".part2[data-v-9aba7792] {\n  height: 680px;\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 100% 100%;\n}\n.part3[data-v-9aba7792] {\n  width: 100%;\n  padding-bottom: 50px;\n}\n.part4[data-v-9aba7792] {\n  height: 680px;\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center;\n  background-size: 100% 100%;\n}\n.part5[data-v-9aba7792] {\n  height: 650px;\n  width: 100%;\n  margin-bottom: 50px;\n}\n.common-cont[data-v-9aba7792] {\n  background: #fff;\n}\n", ""]);
// Exports
module.exports = exports;
