export const allData = {
  views: [
    {
      name: '全过程监督'
    },
    {
      name: '常态化监督'
    },
    {
      name: '立体式监督'
    },
    {
      name: '长效化监督'
    },
    {
      name: '精准化监督'
    },
    {
      name: '数智化监督'
    }
  ],
  norm: [
    {
      info: '作为主要起草单位，先后参与全国一体化政务服务平台标准、司法行业标准以及北京、广东、深圳等地方性标准的制修订工作，标准内容涵盖行政执法电子证照、行政执法数据元、电子案卷等，助力全国行政执法及行政执法监督信息化建设'
    }
  ],
  intro: [
    {
      name: '监督工作门户',
      content:
        '为各级执法监督部门提供集消息、内容、通讯为一体的综合性门户，提供通知公告、工作动态、信息查询、系统链接等功能，建立行政执法监督多级沟通联络机制，提升监督工作效率'
    },
    {
      name: '组织队伍管理系统',
      content:
        '提供对监督部门、监督人员、执法部门、执法主体、执法人员等组织队伍信息的动态管理，实现执法人员学习、培训和考试的全程线上管理，通过动态归集和融合组织队伍信息形成组织队伍库，是规范行政执法的基石'
    },
    {
      name: '执法事项监督系统',
      content:
        '提供对执法事项、裁量基准、法律法规等执法依据信息的动态化管理，动态归集和融合执法事项、裁量基准、法律法规等执法依据，形成执法事项库，为行政执法及执法监督工作开展提供有利抓手'
    },
    {
      name: '资格证件管理系统',
      content:
        '提供行政执法证和行政执法监督证的申领、补办等全流程在线办理，并可对接基础要素系统、执法办案系统，实现人员证件实时更新、资格预警提醒、证件在线查询、电子亮证等应用'
    },
    {
      name: '执法行为监督系统',
      content:
        '通过对行政许可、行政检查、行政处罚、行政强制、行政征收、行政征用等执法行为信息的归集治理，并关联执法要素和法律法规形成执法行为信息库，提供各类执法行为的查询浏览，作为行政执法行为监督的数据支撑'
    },
    {
      name: '智能预警监督系统',
      content:
        '一是建立执法主体、执法人员画像，实现对执法队伍的全方位监督；二是根据相应的预警监督规则对执法行为进行监督预警，实现监督前移；三是对归集的各类行政执法数据进行质量监测评估'
    },
    {
      name: '智能案卷评查系统',
      content:
        '利用信息化技术实现案卷评查电子化、便捷化、智能化，提升评查效率和评查质量。同时建立“一卷一反馈”的工作模式，及时、准确地反馈案卷问题，便于被评查单位进行整改'
    },
    {
      name: '多级考核评价系统',
      content:
        '面向各级监督部门、执法部门，通过构建多级执法效能监督指标体系，形成“年度考核+日常监测”、“人工考核+智能考核”的工作机制，支撑本区域各级行政执法效能的考核评价工作'
    },
    {
      name: '监督案件办理系统',
      content:
        '支持对行政执法监督案件多渠道线索登记以及案件受理、立案、办理等全流程信息的维护、查询和跟踪，实现对行政执法监督案件信息的动态收集，掌握监督案件办理动态'
    },
    {
      name: '法律法规资源库',
      content:
        '涵盖宪法法律、行政法规、部门规章等中央法规和地方性法规、地方政府规章、规范性文件等地方法规，支持使用标题检索、全文检索、标签检索、精确检索、模糊检索、高级检索等多种方式检索，同时支持现行版、历史版的切换、上位法查看，支持通过发布机关、行政区划、时效性等维度精准定位'
    },
    {
      name: '综合统计分析系统',
      content:
        '一是提供对执法要素信息和执法行为信息的各维度、多条件查询统计；二是结合PC端应用场景构建各类执法数据分析模型，对各类执法要素、执法行为开展统计分析；三是围绕本地区行政执法总体情况和执法水平，以及各领域各地区差异化情况、本地区本领域突出问题等要点，结合系统数据生成各类执法分析报告'
    },
    {
      name: '大数据分析',
      content:
        '通过大屏端执法大数据分析，包括执法队伍结构、执法行为态势、执法资源配置、执法履职情况等各分析维度，掌握行政执法的基本状况、探索行政执法的基本规律，发现行政执法的薄弱环节和突出问题，以及各地区的差异，科学评估执法效能'
    },
  ],
  features: [
    {
      name: '宏观监督&nbsp&nbsp&nbsp&nbsp&nbsp+&nbsp&nbsp&nbsp&nbsp&nbsp个案监督',
      content:
        '通过大数据分析，掌握行政执法的基本状况、探索行政执法的基本规律，发现行政执法的薄弱环节和突出问题，实现对行政执法的宏观监督；内嵌执法风险点规则，实时预警各类合法性、规范性问题，有效规避执法风险'
    },
    {
      name: '层级监督&nbsp&nbsp&nbsp&nbsp&nbsp+&nbsp&nbsp&nbsp&nbsp&nbsp社会监督',
      content: '整合执法部门自我监督、政府监督机关层级监督、市场监管信用监督、行政复议诉讼纠错监督、社会公众监督，从不同层面不同维度形成多主体、多层面、立体式监督，实现监督合力'
    },
    {
      name: '事前规范&nbsp&nbsp&nbsp&nbsp&nbsp+&nbsp&nbsp&nbsp&nbsp&nbsp事后评价',
      content: '对执法队伍、执法事项进行事前规范管理；对执法行为进行事中指导监督、事后监督评价，达到完善行政执法监督制度、健全行政执法监督机制、创新行政执法监督方式的目的'
    },
    {
      name: '智能发现&nbsp&nbsp&nbsp&nbsp&nbsp+&nbsp&nbsp&nbsp&nbsp&nbsp人工核查',
      content:
        '采用人工智能技术，实时监测行政执法行为，精准识别合法性、规范性问题及其他异常情况，及时发现潜在风险；结合人工核查，对执法存在的问题及时督查、整改和纠正；加强对行政执法人员的培训和指导，提高行政执法质量'
    }
  ],
  cases: [
    {
      img: 'supervision-case1.png',
      name: '全国行政执法综合管理监督信息系统',
      content:
        '全国行政执法综合管理监督信息系统秉持“统一谋划、标准先行、互联互通、数据赋能”理念，建设数据元标准、数据交换规范、工作平台、执法要素管理、执法证件管理、执法行为管理、典型案例库、综合查询统计分析、全国行政执法大数据分析平台、全国行政执法信息资源库等内容，推进跨地区、跨部门执法信息互联互通、数据共享，助力全国四级行政执法协调监督体系建设'
    },
    {
      img: 'supervision-case2.png',
      name: '北京市行政执法信息服务平台',
      content:
        '北京市行政执法信息服务平台建设了权力清单动态管理、多级绩效考核管理、电子案卷评查、行政执法协作办案、政务服务权力运行管理、行政执法办案、行政执法监督链、辅助决策等17个系统，实现全市行政执法数据的全口径归集、全过程监督、全流程记录、多维度评价、多系统共享。助力行政执法体制改革，落实“三项制度”要求；横向联通各部门行政执法数据共享，纵向拓展街乡行政执法监督层级管理；保障执法监督数据可信，提高行政执法公信力；提升辅助政府科学决策水平和城市精治共治法治水平'
    },
    {
      img: 'supervision-case3.png',
      name: '广东省行政执法监督网络平台',
      content:
        '广东省行政执法监督网络平台涵盖执法队伍管理、执法效能评价、案卷评查、大数据分析系统等内容，支持全省四级监督工作开展，构建执法队伍、执法事项等6 个主题700 余项指标的分析体系，提供大屏、PC、移动三端应用。坚持条块兼顾，构建层级监督、领域监督，打造体系化执法监督新方式'
    },
    {
      img: 'supervision-case6.png',
      name:'云南省“区块链+行政执法和监督”平台',
      content:'云南省“区块链+行政执法和监督”平台涵盖行政执法办案平台、行政执法监督平台、行政执法信息公示平台、区块链应用平台、决策支持平台等内容，支持全省四级行政执法监督体系，实现执法数据同步上链、监督工作链上开展、执法与监督一体化，强化行政执法事前规范、事中指导、事后考核，及时发现行政执法的难点、痛点、堵点，通过科技手段全面提升监督质效，实现“大系统共治、大平台共享、大数据慧治”'
    },
    {
      img: 'supervision-case7.png',
      name:'黑龙江省行政执法综合管理监督平台',
      content:'黑龙江省行政执法综合管理监督平台包括资格证件管理、执法行为监督、智能预警监督、电子案卷评查、监督案件管理、执法公示、综合统计分析、移动监督、辅助决策等内容，实现全省执法要素一体化联动管理、执法数据全量化动态归集共享、执法问题智能化及时预警、执法结果高效化公示，确保全省行政执法资质合法、行为规范、数据安全、分析可靠，打造涵盖机构、队伍、行为、公示、效能、履责的“六维一体”四级监督体系，实现全方位、全过程监督，提高全省行政执法质量'
    },
    {
      img: 'supervision-case4.png',
      name: '深圳法治政府信息平台',
      content:
        '深圳法治政府信息平台内容涵盖智能预警监督、执法综合监测、案卷评查管理、社会舆情监控、培训考试管理、执法效能评价、数据分析研判等内容，实现对全市行政执法数据的归集，对行政检查、行政处罚、行政强制三类执法行为实施的全流程动态监督。个案监督和宏观监督相结合，支撑全市三级行政执法协调监督体系建设，达到“夯实监督基础、丰富监督手段、强化监督实效”的效果'
    },
  ],

  moreCases: [
    {
      img: '贵州省司法云平台-执法监督.png',
      name: '贵州省司法云平台'
    },
    {
      img: '河北省行政执法综合管理监督信息系统.png',
      name: '河北省行政执法综合管理监督信息系统'
    },
    {
      img: '山西省行政执法协调监督平台-行政执法监督管理.png',
      name: '山西省行政执法协调监督平台'
    },
    {
      img: '辽宁省行政执法监督管理信息化平台.png',
      name: '辽宁省行政执法监督管理信息化平台'
    },
    {
      img: '海南省行政执法监督平台.png',
      name: '海南省行政执法监督平台'
    },
    {
      img: '新疆维吾尔自治区执法监督平台.png',
      name: '新疆维吾尔自治区<br/>行政执法综合管理监督信息系统'
    },
    {
      img: '新疆生产建设兵团行政执法综合管理监督系统.png',
      name: '新疆生产建设兵团行政执法综合管理监督系统'
    },
    {
      img: '西藏行政执法监督管理信息化平台.png',
      name: '西藏行政执法监督管理信息化平台'
    },
    {
      img: '河南省行政执法综合管理监督信息系统.png',
      name: '河南省行政执法综合管理监督信息系统'
    },
    {
      img: '湖北省行政执法综合管理监督信息系统.png',
      name: '湖北省行政执法综合管理监督信息系统'
    },
    {
      img: '济宁市行政执法综合管理监督平台.png',
      name: '济宁市行政执法综合管理监督平台'
    },
    {
      img: '抚州市行政执法和行政监督平台.png',
      name: '抚州市行政执法和行政执法监督平台'
    },
    {
      img: 'xtxzzf.jpg',
      name: '邢台市行政执法与行政执法监督一体化平台'
    },
    {
      img: 'jmzhzfbajdxt.png',
      name: '荆门市行政执法监督平台'
    },
    {
      img: '',
      name: ''
    },

    // {
    //   img: '黑龙江省行政执法业务平台.png',
    //   name: '黑龙江省行政执法<br/>综合管理监督信息平台'
    // },
    // {
    //   img: '广东水政执法监督指挥系统.png',
    //   name: '广东省水政执法<br/>和执法监督指挥系统'
    // },
    // {
    //   img: '云南省区块链行政执法和监督平台.png',
    //   name: '云南省“区块链+行政执法和监督”平台'
    // },
    
    // {
    //   img: '山西省行政执法协调监督平台-行政执法监督管理.png',
    //   name: '山西省行政执法协调监督平台'
    // },
    // {
    //   img: '云南省行政执法综合管理监督信息系统.png',
    //   name: '云南省行政执法<br>综合管理监督信息系统'
    // },
  ]
}
