
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'
import ApplicationPop from '@/components/ApplicationPop/index.vue'
export default defineComponent({
  name: '',
  components: {
    ApplicationPop
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const dialogVisible = ref(false)
    const showDialog = (value: boolean) => {
      dialogVisible.value = value
    }
    const imgs = [
      'title-icon-1.png',
      'title-icon-2.png',
      'title-icon-3.png',
      'title-icon-4.png',
      'title-icon-5.png',
      'title-icon-6.png'
    ]
    const innerData = ref<IComObj[]>([])

    innerData.value = props.data
    imgs.forEach((item: string, index: number) => {
      innerData.value[index].img = require('@/assets/images/WSupervision/' +
        item)
    })

    return {
      innerData,
      dialogVisible,
      showDialog
    }
  }
})
