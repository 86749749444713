// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/WSupervision/feature-icon1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/WSupervision/feature-icon2.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/WSupervision/feature-icon3.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/images/WSupervision/feature-icon4.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".part2-ul[data-v-02abfc2f] {\n  display: flex;\n  justify-content: space-between;\n}\n.part2-ul li[data-v-02abfc2f] {\n  width: 280px;\n  position: relative;\n  display: flex;\n  /* justify-content: center; */\n  flex-direction: column;\n  align-items: center;\n}\n.part2-ul li .feature-title[data-v-02abfc2f] {\n  font-size: 20px;\n  color: #333333;\n  font-weight: 700;\n  margin-bottom: 15px;\n  text-align: center;\n  width: 100%;\n  height: 200px;\n  line-height: 200px;\n  transition: all 0.5s;\n  padding-left: 20px;\n}\n.part2-ul li .feature-title[data-v-02abfc2f]:hover {\n  transform: scale(1.1);\n}\n.part2-ul li:nth-child(1) .feature-title[data-v-02abfc2f] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.part2-ul li:nth-child(2) .feature-title[data-v-02abfc2f] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.part2-ul li:nth-child(3) .feature-title[data-v-02abfc2f] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.part2-ul li:nth-child(4) .feature-title[data-v-02abfc2f] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.part2-ul li p[data-v-02abfc2f] {\n  font-size: 16px;\n  color: #777777;\n  line-height: 24px;\n  width: 220px;\n  margin: 0 auto;\n  text-align: justify;\n  display: inline-block;\n  word-break: break-all;\n  padding-left: 20px;\n}\n", ""]);
// Exports
module.exports = exports;
