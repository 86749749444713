
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
export default defineComponent({
  components: { Swiper, SwiperSlide },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const imgs = [
      'center-icon1.png',
      'center-icon2.png',
      'center-icon4.png',
      'center-icon13.png',
      'center-icon7.png',
      'center-icon5.png',
      'center-icon6.png',
      'center-icon8.png',
      'center-icon10.png',
      'center-icon9.png',
      'center-icon11.png',
      'center-icon12.png',
    ]
    const innerData = ref<IComObj[]>([])
    innerData.value = props.data

    imgs.forEach((item: string, index: number) => {
      innerData.value[index].img = require('@/assets/images/WSupervision/' +
        item)
    })
    // 把数据分为两半 分别放在一个slide里面
    const half = Math.ceil(innerData.value.length / 2)
    const arr = [innerData.value.splice(0, half), innerData.value.splice(-half)]
    return {
      arr,
      modules: [Autoplay, Pagination]
    }
  }
})
